﻿$(function () {

    function TraceViewer(id) {
        this._id = id;
    }

    TraceViewer.prototype.createChart = function (options) {
        var stripLines = [], traces = [], annotations = [],
            i, selector = this.selector, $proxy = this;

        //Cache trace viewer options
        this.traceOptions = options;

        //Base unit is used for lossless conversion of distance from different units
        this.baseUnit = options.xAxis.unit;

        //Convert shaded areas into striplines
        for (i = 0; i < options.shadedAreas.length; i++) {
            stripLines.push({
                zIndex: "behind",

                //Starting point of the shaded area
                start: options.shadedAreas[i].start,

                //Ending point of the shaded area
                end: options.shadedAreas[i].end,

                //Color of the shaded area
                color: options.shadedAreas[i].color,

                //Border width of the shaded area
                borderWidth: 0,

                visible: true
            });
        }

        annotations.push({
            visible: true,
            content: options.cursorA.id,
            coordinateUnit: "points",
            x: options.cursorA.xAxisPosition,
            y: options.yAxis.minValue,
            verticalAlignment: "top",
            horizontalAlignment: 'left',
            margin: {bottom: 0}
        });
        annotations.push({
            visible: true,
            content: options.cursorB.id,
            coordinateUnit: "points",
            x: options.cursorB.xAxisPosition,
            y: options.yAxis.minValue,
            verticalAlignment: "top",
            horizontalAlignment: 'right',
            margin: {bottom: 0}
        });

        for (i = 0; i < options.markers.length; i++)
            annotations.push({
                visible: true,
                content: this.createGraphMarker(options.markers[i], "chartMarker" + i),
                coordinateUnit: "points",
                x: options.markers[i].xAxisPosition,
                y: options.yAxis.minValue,
                verticalAlignment: "top",
                margin: {bottom: 0}
            });

        //Convert traces into series
        for (i = 0; i < options.traces.length; i++) {
            traces.push({
                name: options.traces[i].name,
                visible: (options.traces[i].shouldDisplay ? "visible" : "hidden"),
                fill: options.traces[i].lineFormat.color,
                width: options.traces[i].lineFormat.thickness,
                dashArray: options.traces[i].lineFormat.style.dashed ? [1, 5] : null,
                dataSource: options.traces[i].dataPoints,
                xName: 'x',
                yName: 'y',
                type: "line"
            });
        }
        var time = new Date();

        var zoomFactor = this.initialAutoScale(options);

        var savedZoomRange = options.TraceViewerService.getZoom(options.getFiberConfigData());
        if (window.fromLinkMap == false) {
            savedZoomRange = undefined;
        }

        //Create chart with provided model
        $(this._id).ejChart({
            primaryXAxis: {
                title: {
                    text: options.xAxis.caption + " " + options.xAxis.unit
                },
                range: {
                    min: options.xAxis.minValue,
                    max: options.xAxis.maxValue,
                    interval: options.xAxis.tickMark
                },
                stripLine: stripLines,
                labelFormat: "{value} " + options.xAxis.unit,
                labelIntersectAction: 'multiplerows',
                maximumLabels: 3,
                zoomFactor: savedZoomRange ? savedZoomRange.XAxis.zoomFactor : zoomFactor,
                zoomPosition: savedZoomRange ? savedZoomRange.XAxis.zoomPosition : 0,
                multiLevelLabels:[{ font : { fontFamily : "sans-serif"} }]
            },
            zooming: {
                enable: true,
                enableDeferredZoom: true,
                type: 'x',
                zoomLimit: 0.001,
                xZoomFactor: 0.5, //Horizontal scale factor
                yZoomFactor: 0.5, //Vertical scale factor
                stickTo: ['center', 'middle'] //Specifies whether chart should be zoomed from left, right or center/top, middle, bottom to chart area
            },
            enableCanvasRendering: false,
            isResponsive: true,
            panStart: function () {
                $($proxy._id).ejWaitingPopup({showOnInit: true, showImage: true});
            },
            panEnd: function () {
                $($proxy._id).ejWaitingPopup("hide");
            },
            primaryYAxis: {
                title: {
                    text: options.yAxis.caption + " " + options.yAxis.unit
                },
                range: {
                    min: options.yAxis.minValue,
                    max: options.yAxis.maxValue,
                    interval: options.yAxis.tickMark
                },
                zoomFactor: savedZoomRange ? savedZoomRange.YAxis.zoomFactor : 1,
                zoomPosition: savedZoomRange ? savedZoomRange.YAxis.zoomPosition : 0,
                labelFormat: "{value} " + options.yAxis.unit,
                multiLevelLabels:[{ font : { fontFamily : "sans-serif"} }]
            },
            axesLabelRendering: function(args) {
                //get the current label
                var label = args.data.label;

                //Check whether x-axis labels are rendering or y-axis labels are rendering
                if (args.data.axis.orientation.toLowerCase() == 'vertical') {
                    //Change the caption without affecting the values
                    label.Text = options.primaryYAxisLabelCustom(parseFloat(label.Value));
                }
                else if (args.data.axis.orientation.toLowerCase() == 'horizontal') {
                    label.Text = options.primaryXAxisLabelCustom(parseFloat(label.Value));
                }
            },
            series: traces,
            legend: {
                visible: true,

                //Do not hide series (trace) during legend item click
                // toggleSeriesVisibility: false
            },
            legendItemClick: function (args) {
                var dataLabelContainer = document.getElementById(this._id + "_dataLabels"),
                    seriesIndex = $.inArray(args.data.series, this.model.series) + 1,
                    label, display;
                $proxy.traceOptions.traces[seriesIndex - 1]._hideDataLabel = true;

                //Hide or show datalabel of series in cursor A
                if (dataLabelContainer.children.length > seriesIndex) {
                    label = dataLabelContainer.children[seriesIndex];
                    display = label.style.display;
                    label.style.display = (display == 'none' ? 'block' : 'none');
                }

                //Hide or show datalabel of series in cursor B
                if (dataLabelContainer.children.length > seriesIndex + 1 + this.model.series.length) {
                    label = dataLabelContainer.children[seriesIndex + 1 + this.model.series.length];
                    display = label.style.display;
                    label.style.display = (display == 'none' ? 'block' : 'none');
                }
            },
            annotations: annotations,

            chartAreaBoundsCalculate: function (sender) {

                sender.model.annotations[0].y = sender.model.primaryYAxis.visibleRange.max;
                sender.model.annotations[1].y = sender.model.primaryYAxis.visibleRange.max;

                for (var i = 2; i < sender.model.annotations.length; i++) {
                    //Place markers at the bottom of y-axis
                    sender.model.annotations[i].y = sender.model.primaryYAxis.visibleRange.min;
                }
            },
            load: function() {
                this.zoomed = true;
            },
            loaded: function (sender) {
                var selectorA = "#annotation_" + this._id + "_" + options.cursorA.id + "_0",
                    selectorB = "#annotation_" + this._id + "_" + options.cursorB.id + "_1",
                    cursorA = $(selectorA),
                    cursorB = $(selectorB);
                var cursorRect;
                if (cursorA[0] != undefined) {
                    cursorRect = cursorA[0].getBoundingClientRect();
                }
                cursorA._type = "left";
                cursorB._type = "right";

                sender.dataLabelContainer = document.getElementById(this._id + "_dataLabels");
                if (!sender.dataLabelContainer) {
                    $(document.body).append("<div id='" + this._id + "_dataLabels'></div>");
                    sender.dataLabelContainer = $("#" + this._id + "_dataLabels")[0];
                }

                $proxy.arrangeCursor(sender, cursorA, cursorB, options.cursorA, options.cursorMoved, sender.model.annotations[0].x);

                $proxy.arrangeCursor(sender, cursorB, cursorA, options.cursorB, options.cursorMoved, sender.model.annotations[1].x);

                ($proxy.selectedCursor === 1) ? $proxy.setDashStyle(cursorB, cursorA, options.cursorB.cursorFormat.fillColor)
                    : $proxy.setDashStyle(cursorA, cursorB, options.cursorA.cursorFormat.fillColor);

                if (!$proxy._eventsBinded) {
                    $($proxy._id).bind("mousemove touchmove pointerMove MSPointerMove", function (e) {
                        var cursorA1 = $(selectorA), cursorB1 = $(selectorB);
                        cursorA1._type = "left";
                        cursorB1._type = "right";
                        cursorA1._center = cursorA._center;
                        cursorB1._center = cursorB._center;
                        $proxy.chartMouseMove(e, sender, cursorA1, cursorB1, options.cursorA, options.cursorB, options.cursorMoved);
                    });
                    $($proxy._id).bind("mouseup touchend pointerUp MSPointerUp mouseleave touchleave pointerleave", function (e) {
                        var cursorA1 = $(selectorA), cursorB1 = $(selectorB);
                        cursorA1._type = "left";
                        cursorB1._type = "right";
                        $proxy.chartMouseUp(e, sender, cursorA1, cursorB1, options.cursorA, options.cursorB);
                    });
                    $proxy._eventsBinded = true;
                }

                if (options.loadCompleted != null)
                    $.isFunction(options.loadCompleted) ? options.loadCompleted({zoomFactorX: sender.model.primaryXAxis.zoomFactor}) : window[options.loadCompleted].call(null, {zoomFactorX: sender.model.primaryXAxis.zoomFactor});
            },
            zoomed: function (sender) {
                console.log("zoomed to: ", sender.model.primaryXAxis.visibleRange);
                options.TraceViewerService.setZoom({
                    XAxis: {
                        zoomFactor : sender.model.primaryXAxis.zoomFactor,
                        zoomPosition: sender.model.primaryXAxis.zoomPosition
                    },
                    YAxis: {
                        zoomFactor : sender.model.primaryYAxis.zoomFactor,
                        zoomPosition: sender.model.primaryYAxis.zoomPosition
                    }
                }, options.getFiberConfigData());
            }
        });

        //Cache ejChart instance in TraceViewer
        this.chart = $(this._id).ejChart("instance");
        this.chart._enableZoomingButtons();

        return this;
    };

    TraceViewer.prototype.moveCursor = function (location, cursorIndex, cursorOption, cursor, oppositeCursor) {
        if (cursorIndex == 0 || cursorIndex == 1) {

            var model = this.chart.model, seriesLength = model.series.length, bounds = model.m_AreaBounds,
                axis = model.primaryXAxis, cursorChild = cursor.children(), i = 0, left,
                center = parseFloat(cursorChild[0].getAttribute("x1"));

            model.annotations[cursorIndex].x = location;

            if (location >= axis.visibleRange.min && location <= axis.visibleRange.max) {
                for (; i < seriesLength; i++)
                    (cursorOption["_dataLabel" + i]) && !this.traceOptions.traces[i]._hideDataLabel && $(cursorOption["_dataLabel" + i]).css({
                        "display": "block",
                        "visibility": "visible"
                    });
                $(cursorOption._dataLabel).css("display", "block");
                if (cursor.css("visibility") == "hidden") {
                    left = this.getXAxisValue(location, axis);
                    cursor.css({"display": "block", "left": left});
                    this.arrangeCursor(this.chart, cursor, oppositeCursor, cursorOption, this.traceOptions.cursorMoved, location);
                    center = parseFloat(cursorChild[0].getAttribute("x1"));
                    cursor.css({"left": (left - center)});
                    (cursorOption.showDataLabels) && this.displayDataLabels(this.chart, cursorOption, cursor[0].getBoundingClientRect(), center, bounds);
                }
                else {
                    cursor.css({"display": "block", "left": (this.getXAxisValue(location, axis) - center)});
                    (cursorOption.showDataLabels) && this.displayDataLabels(this.chart, cursorOption, cursor[0].getBoundingClientRect(), center, bounds);
                    $.isFunction(this.traceOptions.cursorMoved) ? this.traceOptions.cursorMoved({difference: (model.annotations[1].x - model.annotations[0].x)}) : window[this.traceOptions.cursorMoved].call(null, {difference: (model.annotations[1].x - model.annotations[0].x)});
                }
            }
            else {
                for (; i < seriesLength; i++)
                    (cursorOption["_dataLabel" + i]) && $(cursorOption["_dataLabel" + i]).css({
                        "display": "none",
                        "visibility": "hidden"
                    });
                $(cursorOption._dataLabel).css("display", "none");
                cursor.css("display", "none");
            }
        }
    };

    TraceViewer.prototype.getUnitConversionFactor = function (previousUnit, currentUnit) {
        switch (previousUnit + 'to' + currentUnit) {

            //Metre to other unit conversions
            case 'mtokm':
                return 0.001;
            case 'mtoft':
                return 3.28;
            case 'mtokft':
                return 0.00328;
            case 'mtomi':
                return 0.000621371;

            //Kilo metre to other unit conversions
            case 'kmtom':
                return 1000;
            case 'kmtoft':
                return 3280.84;
            case 'kmtokft':
                return 3.28;
            case 'kmtomi':
                return 0.621371;

            //Foot to other unit conversions
            case 'fttom':
                return 0.3048;
            case 'fttokm':
                return 0.0003048;
            case 'fttokft':
                return 0.001;
            case 'fttomi':
                return 0.000189394;

            //Kilo foot to other unit conversions
            case 'kfttom':
                return 304.8;
            case 'kfttokm':
                return 0.3048;
            case 'kfttoft':
                return 1000;
            case 'kfttomi':
                return 0.189394;

            //Miles to other unit conversions
            case 'mitom':
                return 1609.34;
            case 'mitokm':
                return 1.60934;
            case 'mitoft':
                return 5280;
            case 'mitokft':
                return 5.28;
        }

        //For conversions between same unit types like m to m, km to km, ft to ft, etc..
        return 1;
    };

    //Method for lossless conversion of x-axis unit
    TraceViewer.prototype.changeUnit = function (unit) {
        var unitMultiplier, i, j, series,
            chart = this.chart,
            model = this.traceOptions,
            seriesLength, dataLength,
            cursorUnit;

        chart.model.primaryXAxis.title.text = model.xAxis.caption + " " + unit;
        chart.model.primaryXAxis.labelFormat = "{value} " + unit;

        unitMultiplier = this.getUnitConversionFactor(this.baseUnit, unit);
        cursorUnit = this.getUnitConversionFactor(model.xAxis.unit, unit);
        model.xAxis.unit = unit;


        //Change the range of x-axis based on selected unit
        chart.model.primaryXAxis.range.min = model.xAxis.minValue * unitMultiplier;
        chart.model.primaryXAxis.range.max = model.xAxis.maxValue * unitMultiplier;
        chart.model.primaryXAxis.range.delta = chart.model.primaryXAxis.range.max - chart.model.primaryXAxis.range.min;
        chart.model.primaryXAxis.range.interval = undefined;

        //Change cursor position based on selected unit
        chart.model.annotations[0].x *= cursorUnit; //cursor A
        chart.model.annotations[1].x *= cursorUnit; //cursor B

        //Change marker position based on selected unit
        for (i = 0; i < model.markers.length; i++) {
            chart.model.annotations[i + 2].x = model.markers[i].xAxisPosition * unitMultiplier;
        }

        //change shaded area location based on selected unit
        for (i = 0; i < model.shadedAreas.length; i++) {
            chart.model.primaryXAxis.stripLine[i].start = model.shadedAreas[i].start * unitMultiplier;
            chart.model.primaryXAxis.stripLine[i].end = model.shadedAreas[i].end * unitMultiplier;
        }

        seriesLength = chart.model.series.length;
        for (i = 0; i < seriesLength; i++) {
            dataLength = chart.model.series[i].points.length;
            series = chart.model.series[i];
            for (j = 0; j < dataLength; j++) {
                series.points[j].xValue = series.dataSource[j][series.xName] * unitMultiplier;
                series.points[j].x = series.points[j].xValue;
            }
        }

        chart.redraw(true);

    };

    //Common code for left and right marker movement
    TraceViewer.prototype.moveToMarker = function (location, cursorOption, index) {
        var axis, oppositeIndex, annotations;
        if (location != null) {

            axis = this.chart.model.primaryXAxis;

            if ((location < axis.visibleRange.min && location >= axis.range.min) || (location > axis.visibleRange.max && location <= axis.range.max)) {
                if (index != null) {
                    oppositeIndex = index === 0 ? 1 : 0;
                    annotations = this.chart.model.annotations;
                    annotations[index].x = location;
                    if ((index === 0 && location > annotations[oppositeIndex].x) || (index === 1 && location < annotations[oppositeIndex].x)) {
                        annotations[oppositeIndex].x = location;
                    }
                }
                axis.zoomPosition = (location - axis.range.min - axis.visibleRange.delta / 2) / axis.range.delta;
                this.chart.redraw(true);
                this.setCursorDashStyle(cursorOption);
            }
            else if (index != null)
                this[("moveCursor" + (index === 0 ? "A" : "B"))](location, true);
        }
    };

    TraceViewer.prototype.initialAutoScale = function(options) {

        var includeAdditionalCableLength = 0;

        if (options.shadedAreas && options.shadedAreas.length > 2) {
            for(var i = 0; i < 3; i++) {
                includeAdditionalCableLength += options.shadedAreas[i].end - options.shadedAreas[i].start;
            }
        }

        if (options.markers.length > 0 && options && options.xAxis && options.xAxis.maxValue > 0) {
            return Math.min(1, ((options.markers[options.markers.length -1].xAxisPosition
                + includeAdditionalCableLength) / options.xAxis.maxValue) * 1.25);
        }
        return 1;
    };

    // For a given position find the index of the marker that is the closest.
    TraceViewer.prototype.findClosest = function(position, markers){
        var newIndex = 0, minDelta = Number.MAX_VALUE;
        for (var i = 0; i < markers.length; i++) {
            var val = markers[i].xAxisAdjusted;
            if (Math.abs((position - val)) < minDelta) {
                minDelta = Math.abs(position - val);
                newIndex = i;
            }
        }
        return newIndex;
    };

    TraceViewer.prototype.moveToLeftMarker = function (cursorOption, index) {
        var location, i, markers = this.traceOptions.markers,
            annotations = this.chart.model.annotations,
            position = annotations[this.traceOptions.cursorA == cursorOption ? 0 : 1].x;

        // Find the closest event.
        var newIndex = this.findClosest(position, markers);

        // If the closest is the last event then just set its value.
        if (newIndex === 0) {
            location = markers[0].xAxisAdjusted;
        }

        // Its not the last event. Lets check to see if we should bump it down by one.
        else {

            // Its not on the x location for this event. Lets snap to the closest.
            if (position != markers[newIndex].xAxisAdjusted) {
                location = markers[newIndex].xAxisAdjusted;
            }

            // Its on the x location for this event. The user must want to jump to the next event.
            else {
                if(markers[newIndex - 1] === undefined){
                    location = markers[newIndex].xAxisAdjusted;
                } else {
                    location = markers[newIndex - 1].xAxisAdjusted;
                }
            }
        }

        this.moveToMarker(location, cursorOption, index);
    };

    TraceViewer.prototype.moveToRightMarker = function (cursorOption, index) {
        var location, i, markers = this.traceOptions.markers,
            annotations = this.chart.model.annotations,
            position = annotations[this.traceOptions.cursorA == cursorOption ? 0 : 1].x;

        // Find the closest event.
        var newIndex = this.findClosest(position, markers);

        // If the closest is the last event then just set its value.
        if (newIndex === length - 1) {
            location = markers[newIndex].xAxisAdjusted;
        }

        // Its not the last event. Lets check to see if we should bump it up by one.
        else {

            // Its not on the x location for this event. Lets snap to the closest.
            if (position != markers[newIndex].xAxisAdjusted) {
                location = markers[newIndex].xAxisAdjusted;
            }

            // Its on the x location for this event. The user must want to jump to the next event.
            else {
                if(markers[newIndex +  1] === undefined) {
                    location = markers[newIndex].xAxisAdjusted;
                } else {
                    location = markers[newIndex + 1].xAxisAdjusted;
                }
            }
        }

        this.moveToMarker(location, cursorOption, index);
    };

    TraceViewer.prototype.moveCursorA = function (location, setDashStyle) {
        var option = this.traceOptions.cursorA,
            cursor = $("#annotation_traceViewerChart_" + option.id + "_0"),
            oppositeCursor = $("#annotation_traceViewerChart_" + this.traceOptions.cursorB.id + "_1");
        cursor._type = "left";
        this.moveCursor(location, 0, option, cursor, oppositeCursor);
        (location > this.chart.model.annotations[1].x) && this.moveCursorB(location);
        if (setDashStyle)
            this.setDashStyle(cursor, oppositeCursor, option.cursorFormat.fillColor);
    };

    TraceViewer.prototype.moveCursorB = function (location, setDashStyle) {
        var option = this.traceOptions.cursorB,
            cursor = $("#annotation_traceViewerChart_" + option.id + "_1"),
            oppositeCursor = $("#annotation_traceViewerChart_" + this.traceOptions.cursorA.id + "_0");
        cursor._type = "right";
        this.moveCursor(location, 1, option, cursor, oppositeCursor);
        (location < this.chart.model.annotations[0].x) && this.moveCursorA(location);
        if (setDashStyle)
            this.setDashStyle(cursor, oppositeCursor, option.cursorFormat.fillColor);
    };

    TraceViewer.prototype.displayDataLabels = function (chart, cursorOptions, cursorRect, center, bounds) {
        var element = cursorOptions._dataLabel || document.createElement('div'),
            index = (cursorOptions == this.traceOptions.cursorA) ? 0 : 1,
            xValue = chart.model.annotations[index].x,
            dataLabelFormat = cursorOptions.dataLabelsFormat,
            cursorName = (cursorOptions == this.traceOptions.cursorA) ? "cursorA" : "cursorB",
            cssOption = {
                'position': 'absolute', 'pointer-events': 'none', 'color': dataLabelFormat.fontColor,
                'font-size': dataLabelFormat.fontSize, 'font-weight': dataLabelFormat.fontWeight,
                'font-family': 'sans-serif'
            }, i,
            x = this.getXAxisValue(xValue, chart.model.primaryXAxis), y, yValue, cursorLeft = cursorRect.left + center;

        // Label for displaying marker x value
        element.textContent = chart.model.primaryXAxis.labelFormat.replace('{value}', parseFloat(xValue).toFixed(1));
        // -----------------------------

        cssOption.left = (cursorLeft + dataLabelFormat.xOffset) + 'px';
        cssOption.top = (bounds.Y + bounds.Height - dataLabelFormat.fontSize - dataLabelFormat.yOffset) + 'px';
        $(element).css(cssOption);

        if (!cursorOptions._dataLabel) {
            cursorOptions._dataLabel = element;
            $(chart.dataLabelContainer).append(element);
        }

        if (index === 0)
            $(element).css('left', (parseFloat(cssOption.left) - parseFloat($(element).css("width")) - 2 * dataLabelFormat.xOffset) + 'px');

        // read header height
        var $header = $(".header");
        var $projectHeader = $("#project-header");
        var headerHeight = $header.outerHeight() + $projectHeader.outerHeight() + 81;
        for (i = 0; i < chart.model.series.length; i++) {
            if (chart.model.series[i].visibility === "visible") {
                element = cursorOptions["_dataLabel" + i] || document.createElement('div');
                y = this.getClosestPoint(xValue, chart.model.series[i].points).y;
                if (!this.traceOptions.traces[i][cursorName])
                    this.traceOptions.traces[i][cursorName] = {};
                this.traceOptions.traces[i][cursorName].labelValue = y;
                yValue = this.traceOptions.primaryYAxisLabelCustom(this.getYAxisValue(y, chart.model.primaryYAxis));
                element.textContent = chart.model.primaryYAxis.labelFormat.replace('{value}',
                    this.traceOptions.primaryYAxisLabelCustom(y.toFixed(3)) + 'dB');
                cssOption.top = (headerHeight + i * dataLabelFormat.fontSize) + 'px';
                cssOption.color = chart.model.series[i].fill;
                $(element).css(cssOption);

                if (!cursorOptions["_dataLabel" + i]) {
                    cursorOptions["_dataLabel" + i] = element;
                    $(chart.dataLabelContainer).append(element);
                }

                if (index === 0)
                    $(element).css('left', (parseFloat(cssOption.left) - parseFloat($(element).css("width")) - 2 * dataLabelFormat.xOffset) + 'px');
            }
        }
    };

    //Assume points are in sorted order
    TraceViewer.prototype.getClosestPoint = function (x, points) {
        var minDistance, minIndex, i, distance;

        for (var i = 0; i < points.length; i++) {
            if (x == points[i].x)
                return points[i];
            else if (points[i].x > x) {
                minIndex = i;
                break;
            }
        }
        return minIndex > 0 && (Math.abs(points[minIndex].x - x) > Math.abs(points[minIndex - 1].x - x)) ? points[minIndex - 1] : points[minIndex];
    };

    TraceViewer.prototype.arrangeCursor = function (sender, cursor, oppositeCursor, cursorOption, cursorMoved, cursorPosition) {
        var bounds = sender.model.m_AreaBounds, i = 0, $proxy = this, cursorChild = cursor.children(),
            difference, range = sender.model.primaryXAxis.visibleRange,
            display = (cursorPosition < range.min || cursorPosition > range.max) ? "none" : "block",
            visibility = (display == 'none' || cursorPosition < sender.model.primaryXAxis.visibleRange.min || cursorPosition > sender.model.primaryXAxis.visibleRange.max) ? 'hidden' : 'visible';

        cursorOption._dragStart = null;

        for (; i < sender.model.series.length; i++)
            if (sender.model.series[i].visibility == "visible")
                (cursorOption["_dataLabel" + i]) && $(cursorOption["_dataLabel" + i]).css({
                    "display": display,
                    "visibility": visibility
                });
            else
                (cursorOption["_dataLabel" + i]) && $(cursorOption["_dataLabel" + i]).css({
                    "display": "none",
                    "visibility": visibility
                });
        $(cursorOption._dataLabel).css({"display": display, "visibility": visibility});
        cursor.css({"display": display, "visibility": visibility});

        if (visibility != "hidden") {

            cursor.attr("height", bounds.Height);

            if (cursorChild[0] != undefined) {
                cursorChild[0].setAttribute("y2", bounds.Height);
                cursor._center = parseFloat(cursor.children()[0].getAttribute("x1"));
            }

            cursor.css({"left": (this.getXAxisValue(cursorPosition, sender.model.primaryXAxis) - cursor._center)});

            cursor.css({
                "clip": "rect(0px," + (bounds.X + bounds.Width) + "px," + (bounds.Y + bounds.Height) + "px,0px)",
                top: bounds.Y + "px"
            });
            if (cursorOption.cursorFormat) {
                var cssOption = {
                    "fill": cursorOption.cursorFormat.fillColor,
                    "stroke-dasharray": ((cursorOption.cursorFormat.style == "dashed") ? "5,4" : null),
                    "stroke": cursorOption.cursorFormat.color,
                    "stroke-width": cursorOption.cursorFormat.thickness || 1, "cursor": "ew-resize"
                }

                if (cursorChild[0] != undefined) {
                    $(cursorChild[0]).css(cssOption);
                    cssOption.cursor = "pointer";
                    $(cursorChild[1]).css(cssOption);
                    $(cursorChild[2]).css(cssOption);
                    $(cursorChild[3]).css(cssOption);
                    $(cursorChild[4]).css("cursor", "pointer");
                }
            }

            if (cursorChild != undefined) {
                $(cursorChild).filter(":even").bind("mousedown touchstart pointerDown MSPointerDown", function (e) {
                    $proxy.cursorDown(e, sender, cursor, oppositeCursor, cursorOption);
                });

                $(cursorChild[1]).bind("mousedown touchstart pointerDown MSPointerDown", function (e) {
                    $proxy.moveToLeftMarker(cursorOption, cursor._type == "left" ? 0 : 1, false);
                    cursorOption._move = true;
                    if ($proxy.chart.panning) {
                        $proxy.chart.panning = false;
                        $proxy.panning = true;
                    }

                    $proxy.zooming = sender.model.zooming.enable;
                    sender.model.zooming.enable = false;
                });

                $(cursorChild[3]).bind("mousedown touchstart pointerDown MSPointerDown", function (e) {
                    $proxy.moveToRightMarker(cursorOption, cursor._type == "left" ? 0 : 1, true);
                    cursorOption._move = true;
                    if ($proxy.chart.panning) {
                        $proxy.chart.panning = false;
                        $proxy.panning = true;
                    }

                    $proxy.zooming = sender.model.zooming.enable;
                    sender.model.zooming.enable = false;
                });

                if (cursorChild[0] != undefined && cursor[0] != undefined) {
                    (cursorOption.showDataLabels) && this.displayDataLabels(sender, cursorOption, cursor[0].getBoundingClientRect(), parseFloat(cursorChild[0].getAttribute("x1")), bounds);
                }
            }

            difference = sender.model.annotations[1].x - sender.model.annotations[0].x;
            ($.isFunction(cursorMoved) ? cursorMoved : window[cursorMoved])({difference: difference});
        }
    };

    TraceViewer.prototype.createGraphMarker = function (graphMarker, id) {
        var marker, markerAdded = document.getElementById(id), children;
        (!markerAdded) && (marker = $("#chartMarker").clone().attr({id: id})[0]);
        children = $(marker).children();
        $(children[0]).css({fill: graphMarker.backgroundColor});
        $(children[1]).css({fill: graphMarker.fontColor});
        $(children[1]).text(graphMarker.number);
        (!markerAdded) && document.getElementById("annotationTemplate").appendChild(marker);
        return id;
    };

    TraceViewer.prototype.selectCursor = function (cursorOption) {
        this._id = this._id.replace('#', '');
        this.setCursorDashStyle(cursorOption);
        this.moveToMarker(this.chart.model.annotations[cursorOption == this.traceOptions.cursorA ? 0 : 1].x, cursorOption);
    };

    TraceViewer.prototype.setCursorDashStyle = function (cursorOption) {
        var cursorA = $("#annotation_" + this._id + "_" + this.traceOptions.cursorA.id + "_0"),
            cursorB = $("#annotation_" + this._id + "_" + this.traceOptions.cursorB.id + "_1");

        cursorA._type = "left";
        (cursorOption == this.traceOptions.cursorA) ?
            this.setDashStyle(cursorA, cursorB, cursorOption.cursorFormat.fillColor) : this.setDashStyle(cursorB, cursorA, cursorOption.cursorFormat.fillColor);
    };

    TraceViewer.prototype.setDashStyle = function (cursor, oppositeCursor, fill) {
        var cursorChild = cursor.children(),
            dashStyle = "";
        $(cursorChild[0]).css({"stroke-dasharray": dashStyle});
        $(cursorChild[1]).css({"stroke-dasharray": dashStyle});
        $(cursorChild[2]).css({"stroke-dasharray": dashStyle});
        $(cursorChild[3]).css({"stroke-dasharray": dashStyle});
        $(cursorChild[1]).css({"display": "block"});
        $(cursorChild[3]).css({"display": "block"});
        $(cursorChild[2]).css("fill", fill);

        cursorChild = oppositeCursor.children();
        dashStyle = "5,4";
        $(cursorChild[0]).css("stroke-dasharray", dashStyle);
        $(cursorChild[1]).css("stroke-dasharray", dashStyle);
        $(cursorChild[2]).css("stroke-dasharray", dashStyle);
        $(cursorChild[3]).css("stroke-dasharray", dashStyle);
        $(cursorChild[1]).css("display", "none");
        $(cursorChild[3]).css("display", "none");
        $(cursorChild[2]).css("fill", "transparent");
        $(cursor).css("zIndex", 100);
        $(oppositeCursor).css("zIndex", 90);

        this.selectedCursor = cursor._type == 'left' ? 0 : 1;
    };

    TraceViewer.prototype.cursorDown = function (e, chart, cursor, oppositeCursor, cursorOptions) {
        var event = this.traceOptions.cursorSelected;
        cursorOptions._dragStart = (e.clientX || e.originalEvent.changedTouches[0].clientX);
        cursorOptions._clicked = true;

        if (this.chart.panning) {
            this.chart.panning = false;
            this.panning = true;
        }

        this.zooming = chart.model.zooming.enable;
        chart.model.zooming.enable = false;
        this.setDashStyle(cursor, oppositeCursor, cursorOptions.cursorFormat.fillColor);
        ($.isFunction(event) ? event : window[event])({ 'selectedCursor': this.selectedCursor });
    };

    TraceViewer.prototype.cursorMove = function (e, chart, cursor, oppositeCursor, cursorOptions) {
        var clientX = (e.clientX || e.originalEvent.changedTouches[0].clientX),
            bounds = chart.model.m_AreaBounds,
            dragMove = cursorOptions._dragStart ? cursorOptions._dragStart - clientX : 0,
            centerX = parseFloat(cursor.css("left")) - dragMove;
        cursorOptions._dragStart = clientX;
        if (bounds.X <= centerX + cursor._center && centerX + cursor._center < bounds.X + bounds.Width) {
            var xValue = this.getXValue(centerX + cursor._center, chart.model.primaryXAxis);
            cursor._type == "left" ? this.moveCursorA(xValue) : this.moveCursorB(xValue);
        }
    };

    TraceViewer.prototype.getXValue = function (val, xAxis) {
        var xAxisLocation = xAxis.Location, visibleRange = xAxis.visibleRange;
        if (val >= xAxisLocation.X1 && val <= xAxisLocation.X2)
            return visibleRange.min + (val - xAxisLocation.X1) / xAxis.width * visibleRange.delta;
    };

    TraceViewer.prototype.getXAxisValue = function (val, xAxis) {
        var xAxisLocation = xAxis.Location, visibleRange = xAxis.visibleRange;
        if (val >= visibleRange.min && val <= visibleRange.max)
            return xAxisLocation.X1 + (val - visibleRange.min) / visibleRange.delta * xAxis.width;
    };

    TraceViewer.prototype.getYAxisValue = function (val, yAxis) {
        var yAxisLocation = yAxis.Location, visibleRange = yAxis.visibleRange;
        if (val >= visibleRange.min && val <= visibleRange.max)
            return yAxisLocation.Y1 - Math.abs(val - visibleRange.min) / visibleRange.delta * yAxis.height;
    };

    TraceViewer.prototype.chartMouseMove = function (e, chart, cursorA, cursorB, cursorAOptions, cursorBOptions) {
        if (!chart.model.zooming.enable && !cursorAOptions._move && !cursorBOptions._move)
            cursorAOptions._clicked ? this.cursorMove(e, chart, cursorA, cursorB, cursorAOptions, cursorBOptions) : this.cursorMove(e, chart, cursorB, cursorA, cursorBOptions, cursorAOptions);
    };

    TraceViewer.prototype.chartMouseUp = function (e, chart, cursorA, cursorB, cursorAOptions, cursorBOptions) {
        if (this.panning)
            this.chart.panning = this.panning === true ? true : undefined;
        this.panning = undefined;
        chart.model.zooming.enable = true;
        cursorAOptions._dragStart = null;
        cursorBOptions._dragStart = null;
        cursorAOptions._clicked = false;
        cursorBOptions._clicked = false;
        cursorAOptions._move = false;
        cursorBOptions._move = false;
    };

    //$.fn and $.prototype both are same
    $.fn.TraceViewer = function (options) {
        var traceViewerChart;

        //Use $(selector).TraceViewer('instance') to get the instance dynamically
        if (options === "instance")
            return this.data("TraceViewer");

        //Creates the chart and returns the TraceViewer instance
        traceViewerChart = new TraceViewer('#' + this[0].id).createChart(options);
        this.data("TraceViewer", traceViewerChart);
        return traceViewerChart;
    };
});
